import { useEffect } from 'react'

// Workaround to fix ADA issues with Podium plugin.
const setAriaHiddenToPodiumElements = () => {
  setTimeout(() => {
    const recaptchaElements = document.getElementsByName('g-recaptcha-response')

    if (recaptchaElements?.length) {
      recaptchaElements.forEach((element: any) => {
        element.setAttribute('aria-hidden', 'true')
        element.setAttribute('aria-label', 'do not use')
        element.setAttribute('aria-readonly', 'true')
      })
    }
  }, 2000)
}

const useSetAriaLabels = () => {
  useEffect(() => {
    setAriaHiddenToPodiumElements()
  })
}

export default useSetAriaLabels
